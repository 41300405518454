import { useState, useEffect } from "react"
import { toast } from 'sonner'
import { getAll } from "../../services/riders"

import Filters from "../../components/Admin/Riders/Filters"
import Table from "../../components/Admin/Riders/Table"
import Export from "../../components/Admin/Riders/Export"

function Page() {
  const [ initialData, setInitialData ] = useState([])
  const [ initialCount, setInitialCount ] = useState(0)
  const [ data, setData ] = useState([])
  const [ count, setCount ] = useState(0)
  const [ dataPage, setDataPage ] = useState([])
  const [ page, setPage ] = useState(1)
  const numberDocs = 10

  const filterData = async (array, arrayLenght, newPage) => { 

    const newData = []
    let start = ((newPage-1)*numberDocs)
    let end = arrayLenght
    if(((newPage)*numberDocs) < end) end = ((newPage)*numberDocs)
    for (let index = start; index < end; index++) {   
      if(array[index] !== undefined) newData.push(array[index])
    }
    setPage(newPage)  
    return newData
  }


  useEffect(() => {
    
    getInitials()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitials = async () => {    
    getAll()
    .then((result) => {
      setInitialCount(result.length)
      setCount(result.length)
      setInitialData(result)
      setData(result)
      filterData(result, result.length, 1 )
      .then((res) => setDataPage(res))
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  const next = async () => {    
    filterData(data, count, page + 1)
    .then((res) => setDataPage(res))
  }

  const previous = async () => {    
    filterData(data, count, page -1)
    .then((res) => setDataPage(res))
  }  

  const searchBy = async (search, by) => {

    if(search !== "" && search != null) {
      toast("Buscando", {duration:700})
      let searchAux = search.trim().toLowerCase()
      if(by === "id" || by === "mobileNumber") searchAux = Number(searchAux)
      // eslint-disable-next-line array-callback-return
      let newData = initialData.filter(function(obj) {
        if(by === "id" || by === "mobileNumber") {
          return obj[by] === searchAux
        } else {
          if(obj[by] !== null) {
            return obj[by].trim().toLowerCase() === searchAux
          }
        }
      })
      setData(newData)
      setCount(newData.length)
      filterData(newData, newData.length, 1)
      .then((res) => setDataPage(res))
    } else {
      setData(initialData)
      setCount(initialCount)
      filterData(initialData, initialCount, 1)
      .then((res) => setDataPage(res))
    }
    
  }


  return (
    <>
      <section className="pt-5 my-5 container d-grid gap-3">
        <div className="row">
          <h1 className="mt-3 mt-sm-5 text-center">Pasajeros</h1>
        </div>
         <div className="row d-grid gap-2">
            <div className="d-flex justify-content-end">
              <Export />  
            </div>
          </div>
        <div className="row d-grid gap-2">
          <Filters searchBy={searchBy} next={next} previous={previous} page={page} numberDocs={numberDocs} count={count} />
        </div>
        <div className="row">
          <Table dataPage={dataPage} />
        </div>
      </section>
    </>
  )
}

export default Page
