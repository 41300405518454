import PropTypes from 'prop-types'
import { useContext, createContext, useEffect, useState} from "react"
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth"
import { DataContext } from './DataProvider'
import { app } from "../firebase"
import { getById as getDriverById } from '../services/drivers'
import { getById as getRiderById } from '../services/riders'
import Cookies from 'js-cookie'

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {

    const auth = getAuth(app)
    const [ user, setUser ] = useState({})
    const [ DRVRSR, setDRVRSR ] = useState()
    const [ RDRSR, setRDRSR ] = useState()
    const value = useContext(DataContext)
    const [querySnapshot] = value.admins
    
    //Admin
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(auth, provider)
        .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result);
        //const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user

        //Validation
        let validation = false
        querySnapshot.forEach((doc) => {
            if (user.email === doc.email) {
                validation = true
            }
        })
        if(!validation) {
            googleSignOut()
        }
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);
            console.error({errorCode, errorMessage, email, credential})
        })
    }

    const googleSignOut = () => {
        signOut(auth)
    }    

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (currentUser) =>{
            setUser(currentUser)
        })
        return () => {
            unsubscribe()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    //Driver   

    const signInDriver = ({user}) => {
        setDRVRSR(user)
        let idCrypto = `10sa214fae${user?.id}`;        
        Cookies.set("DRVRSR", JSON.stringify(idCrypto), { expires: 1 })
    }

    const signOutDriver = () => {
        setDRVRSR(null)
        Cookies.remove('DRVRSR')
    }    

    
    useEffect(() => {
        let item = Cookies.get("DRVRSR")
        if (item) {
            const aux = JSON.parse(item)
            if(aux) {
                let idDescrypto = aux.replace("10sa214fae", "").replace("e9nt768q", "e").replace("e9Nt768Q", "E").replace("i8wc79i6ew", "i").replace("i8Wc79i6Ew", "I").replace("a5xA4vp", "a").replace("a5xA4VpZ", "A").replace("oz21em3yC", "o").replace("ufat73k9B", "u")
                idDescrypto = idDescrypto.replace("10sa214fae", "").replace("e9nt768q", "e").replace("e9Nt768Q", "E").replace("i8wc79i6ew", "i").replace("i8Wc79i6Ew", "I").replace("a5xA4vp", "a").replace("a5xA4VpZ", "A").replace("oz21em3yC", "o").replace("ufat73k9B", "u")
                getDriverById(idDescrypto)
                .then((result) => {
                    setDRVRSR(result)      
                })
                .catch((e) => {
                  console.error(e)
                })
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    //Rider   

    const signInRider = ({user}) => {
        setRDRSR(user)
        let idCrypto = `10sa214fae${user?.id}`;        
        Cookies.set("RDRSR", JSON.stringify(idCrypto), { expires: 1 })
    }

    const signOutRider = () => {
        setRDRSR(null)
        Cookies.remove('RDRSR')
    }    

    
    useEffect(() => {
        let item = Cookies.get("RDRSR")
        if (item) {
            const aux = JSON.parse(item)
            if(aux) {
                let idDescrypto = aux.replace("10sa214fae", "").replace("e9nt768q", "e").replace("e9Nt768Q", "E").replace("i8wc79i6ew", "i").replace("i8Wc79i6Ew", "I").replace("a5xA4vp", "a").replace("a5xA4VpZ", "A").replace("oz21em3yC", "o").replace("ufat73k9B", "u")
                idDescrypto = idDescrypto.replace("10sa214fae", "").replace("e9nt768q", "e").replace("e9Nt768Q", "E").replace("i8wc79i6ew", "i").replace("i8Wc79i6Ew", "I").replace("a5xA4vp", "a").replace("a5xA4VpZ", "A").replace("oz21em3yC", "o").replace("ufat73k9B", "u")
                getRiderById(idDescrypto)
                .then((result) => {
                    setRDRSR(result)      
                })
                .catch((e) => {
                  console.error(e)
                })
            }
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  

    return (
        <AuthContext.Provider value={{ user, googleSignIn, googleSignOut, DRVRSR, signInDriver, signOutDriver, RDRSR, signInRider, signOutRider }}>
            {children}
        </AuthContext.Provider>
    )
}

AuthContextProvider.propTypes = {
    children: PropTypes.any
}

export const Auth = () => {
    return useContext(AuthContext)
}