import PropTypes from 'prop-types'
import { GrNext } from "react-icons/gr"
import { Link } from "react-router-dom"

export default function Component({ dataPage }) {
  if(dataPage.length > 0) {

    const options = {
      year: "2-digit",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      weekday: "long",
      hour12: true,
      timeZone: 'America/New_York'
    }

    const formatDate = (dateAux) => {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }

    return (
      <>
       <div className="mb-5 table-responsive">
          <table className="table table-hover border">
            <thead className="text-uppercase small ">
              <tr>
                <th scope="col" className="px-3 py-3 ">
                  id
                </th>
                <th scope="col" className="px-3 py-3">
                  nombre
                </th>
                <th scope="col" className="px-3 py-3">
                  apellido
                </th>
                <th scope="col" className="px-3 py-3">
                  correo
                </th>
                <th scope="col" className="px-3 py-3">
                  celular
                </th>
                <th scope="col" className="px-3 py-3">
                  estado
                </th>
                <th scope="col" className="px-3 py-3">
                  banco
                </th>
                <th scope="col"  className="px-3 py-3">
                  registro
                </th>
                <th scope="col" className="px-3 py-3">
                  opciones
                </th>
              </tr>
            </thead>
              <tbody>
                {dataPage.map(doc =>
                  <tr key={doc.id}>
                    <td className="px-3 py-3">
                      <span>{doc.id} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.firstName} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.lastName} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.email} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.mobileNumber} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.status} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <span>{doc.bankName} </span>
                    </td> 
                    <td className="px-3 py-3" style={{minWidth:"300px"}}>
                      <span>{formatDate(doc.registrationTimestamp)} </span>
                    </td> 
                    <td className="px-3 py-3">
                      <Link to={`/admin/driver/${doc.id}`} className="d-flex justify-content-center">
                        <GrNext className="h5 cursor-pointer text-black mt-3" />
                      </Link>
                    </td>                
                  </tr>
                )}

              </tbody>
          </table>
        </div>
      </>
    )
  } else {
    
    return (
      <>
        <hr />
      </>
    )
  } 
}

Component.propTypes = {
  dataPage: PropTypes.array
}
