import PropTypes from 'prop-types'
import { HiOutlineUser, HiUser  } from "react-icons/hi2"
import { getById as getByIdDriver} from "../../../services/drivers"
import { getById as getByIdRider} from "../../../services/riders"
import { toast } from 'sonner'
export default function Component({ dataPage }) {

  if(dataPage.length > 0) {

    const options = {
      year: "2-digit",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      weekday: "long",
      hour12: true,
      timeZone: 'America/New_York'
    }

    const formatDate = (dateAux) => {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }

    const getDriver = async (id) => { 
      const user = await getByIdDriver(id)
      if(user?.id !== "" && user?.id != null) {
        alert(`ID conductor: ${user.id}\nNombre: ${user.firstName} ${user.lastName}\nCorreo: ${user.email}\nCelular: ${user.mobileNumber}`)
      } else toast("Conductor indefinido")
    }  

    const getRider = async (id) => { 
      const user = await getByIdRider(id)
      if(user?.id !== "" && user?.id != null) {
        alert(`ID usuario: ${user.id}\nNombre: ${user.firstName} ${user.lastName}\nCorreo: ${user.email}\nCelular: ${user.mobileNumber}`)
      } else toast("Usuario indefinido")
    }  

    return (
      <>
       <div className="mb-5 table-responsive">
          <table className="table table-hover border">
            <thead className="text-uppercase small ">
              <tr>
                <th scope="col" className="px-3 py-3 ">
                  id
                </th>
                <th scope="col" className="px-3 py-3">
                  fecha
                </th>
                <th scope="col" className="px-3 py-3">
                  fecha finalización
                </th>
                <th scope="col" className="px-3 py-3">
                  estado
                </th>
                <th scope="col" className="px-3 py-3">
                  Dirección
                </th>
                <th scope="col" className="px-3 py-3">
                  Metodo de pago
                </th>
                <th scope="col" className="px-3 py-3">
                  Moneda
                </th>
                <th scope="col" className="px-3 py-3">
                  Monto
                </th>
                <th scope="col" className="px-3 py-3">
                  Conductor
                </th>
                <th scope="col" className="px-3 py-3">
                  Usuario
                </th>
              </tr>
            </thead>
              <tbody>
                {dataPage.map(doc =>
                  <tr key={doc.id}>
                    <td className="px-3 py-3">
                      <small>{doc.id} </small>
                    </td> 
                    <td className="px-3 py-3" style={{minWidth:"200px"}}>
                      <small>{formatDate(doc.requestTimestamp)} </small>
                    </td> 
                    <td className="px-3 py-3" style={{minWidth:"200px"}}>
                      <small>{formatDate(doc.finishTimestamp)} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.status} </small>
                    </td> 
                    <td className="px-3 py-3" style={{minWidth:"200px"}}>
                      <small>{doc.addresses} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.paymentMode} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.currency} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.paidAmount} </small>
                    </td>  
                    <td className="px-3 py-3">
                      <div 
                        onClick={() => {
                          getDriver(doc.driverId)
                        }} 
                        className="d-flex justify-content-center"
                      >
                        <HiOutlineUser className="h5 cursor-pointer text-black mt-3" />
                      </div>
                    </td>      
                    <td className="px-3 py-3">
                      <div 
                        onClick={() => {
                          getRider(doc.riderId)
                        }} 
                        className="d-flex justify-content-center"
                      >
                        <HiUser className="h5 cursor-pointer text-black mt-3" />
                      </div>
                    </td>          
                  </tr>
                )}

              </tbody>
          </table>
        </div>
      </>
    )
  } else {
    
    return (
      <>
        <hr />
      </>
    )
  } 
}

Component.propTypes = {
  dataPage: PropTypes.array
}
