import { useState, useEffect } from "react"
import { toast } from 'sonner'
import { getById } from "../../services/riders"
import { getByRider as getTransactions, create } from "../../services/transactions"
import { getPercentage, getTime } from "../../services/options"
import { getByRider as getRequests } from "../../services/requests"
import { on, off } from "../../utils/onOff"
import { Link, useParams } from 'react-router-dom'
import { GrFormPrevious } from "react-icons/gr"

import Transactions from "../../components/Admin/TransactionsUserDel"
import Requests from "../../components/Admin/RequestsUser"
import Modal from '../../components/Modal'

function Page() {
  const [ element, setElement ] = useState([])
  const [ user, setUser ] = useState()
  const [ valueCashback, setValueCashback ] = useState(0)
  const [ sentValue, setSentValue ] = useState()
  const [ time, setTime ] = useState()
  const [ percentage, setPercentage ] = useState()
  const [ senderBank, setSenderBank ] = useState()
  const [ senderAccount, setSenderAccount] = useState()
  const [ receiverBank, setReceiverBank ] = useState()
  const [ receiverAccount, setReceiverAccount ] = useState()
  const [ dataTransactions, setDataTransactions ] = useState([])
  const [ dataRequests, setDataRequests ] = useState([])
  const { id } = useParams()

  useEffect(() => {
    
    getInitials()
    window.scroll(0, 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    
    setElement(elementCreate)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [percentage, valueCashback, senderBank, receiverBank, sentValue, senderAccount, receiverAccount])

  const options = {
    year: "2-digit",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    weekday: "long",
    hour12: true,
    timeZone: 'America/New_York'
  }

  const formatDate = (dateAux) => {
    if(dateAux) {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }
  }

  const getInitials = async () => {
    getPercentage()
    .then((result) => {
      setPercentage(result.value)
    })
    .catch((e) => {
      console.error(e)
    })

    if(id !== 'undefined' && id !== undefined) {
      getById(id)
      .then((resUser) => {
        setUser(resUser)
        getTransactions(resUser.id)
        .then((resTransactions) => {
          setDataTransactions(resTransactions.data)      

          getTime() // se obtiene periodo de tiempo de admin
          .then((resTime) => {
            setTime(resTime)
            const dateStart = new Date(resTime.start)
            const dateEnd = new Date(resTime.end)

            getRequests(resUser.id)
            .then((resRequests) => {
              setDataRequests(resRequests)  
              let value = 0
              resRequests.forEach(item => {
                const dateRequest = new Date(item.requestTimestamp)
                if(dateRequest >= dateStart && dateRequest <= dateEnd) { //valicación de periodos de tiempo
                  if(resTransactions.data[0]?.created) { //validación de pagos hechos
                    const dateCashback = new Date(resTransactions.data[0]?.created)
                    if(dateRequest >= dateCashback) value = value + Number(item.paidAmount)
                  } else value = value + Number(item.paidAmount)
                }
              })
              setValueCashback(value)
            })
            .catch((e) => {
              console.error(e)
            })
          })
          .catch((e) => {
            console.error(e)
          })


        })
        .catch((e) => {
          console.error(e)
        })

      })
      .catch((e) => {
        console.error(e)
      })
      }
  }  

  const selectUser = async () => {    
    if(user?.id !== "" && user?.id != null) {
      setElement(
        <>
          <form className="d-grid gap-3">
            <div>
              <h2>Datos de usuario</h2>
            </div>
            <div className="d-grid gap-2">
              <div className="d-grid">
                <small className="small">Id</small>
                <small><b>{user?.email}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Nombre</small>
                <small><b>{user?.firstName}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Apellido</small>
                <small><b>{user?.lastName}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Correo</small>
                <small><b>{user?.email}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">País ISO</small>
                <small><b>{user?.countryIso}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Celular</small>
                <small><b>{user?.mobileNumber}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Género</small>
                <small><b>{user?.gender}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Estado</small>
                <small><b>{user?.status}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Banco</small>
                <small><b>{user?.bankName}</b></small>
                <small><b>{user?.bankRoutingNumber}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Dirección</small>
                <small><b>{user?.address}</b></small>
              </div>
              <div className="d-grid">
                <small className="small">Fecha de registro</small>
                <small><b>{user?.registrationTimestamp}</b></small>
              </div>
            </div>


            <div className="d-flex justify-content-end">
              <div className="d-flex gap-2">
                <div
                  className="btn btn-light btn-sm rounded-pill border px-3"
                  onClick={() => {
                    off()
                    setElement()
                  }}
                >
                  Cerrar
                </div>
              </div>
            </div>
          </form>
        </>
      )
    } else toast.error("Usuario indefinido")
  }  

  //Create

  const validateCreate = async (e) => {    
    e.preventDefault()
    let now = new Date() 
    let value = Number(e.target.value.value)
    let detail = e.target.detail.value
    let body = {
      type: "cashback",
      rol: "rider",
      value: value,
      status: "completed",
      detail: detail,
      created: now.toISOString(),
      user: user.id.toString()
    }
    create(body)
    .then(() => {
      e.target.value.value = ""
      e.target.detail.value = ""
      toast.success("Registro generado")
      getInitials()
      off()
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  const elementCreate = <>
    <form className="d-grid gap-3" onSubmit={(e) => validateCreate(e)}>
      <div>
        <h2 className=" text-2xl font-extrabold text-zinc-800">Registrar Devolución</h2>
      </div>
      <div>
        <small>Valor estimado a enviar según porcentaje</small>
        <h2>{new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback*percentage/100)}</h2>
      </div>
      <div>
        <small>Porcentaje a aplicar: <b>{percentage}%</b></small>
      </div>
      <small >*Antes de registar la devolución asegúrese de haber generado bien la transacción y tener un comprobante.</small>
      <div>
        <small> Valor real enviado </small>
        <div >
          <input 
            required
            id="value"
            name="value"
            type="number"
            min="0"
            max={`${valueCashback}`}
            className="w-100 px-2 py-2 border rounded-4 bg-transparent fw-bold"
            onChange={ 
              (e) => {
                setSentValue(e.currentTarget.value)
              } 
            }
          />
        </div>
      </div>
      <div>
        <small> Remitente </small>
        <div className="d-grid gap-2">
          <input
            required
            type="text"
            min="0"
            placeholder="Banco"
            className="small w-100 px-2 py-2 border rounded-4 bg-transparent"
            onChange={ 
              (e) => {
                setSenderBank(e.currentTarget.value)
              } 
            }
          />
          <input
            required
            type="text"
            min="0"
            placeholder="Cuenta"
            className="small w-100 px-2 py-2 border rounded-4 bg-transparent"
            onChange={ 
              (e) => {
                setSenderAccount(e.currentTarget.value)
              } 
            }
          />
        </div>
      </div>

      <div>
        <small> Destinatario </small>
        <div className="d-grid gap-2">
        <input
            required
            type="text"
            min="0"
            placeholder="Banco"
            className="small w-100 px-2 py-2 border rounded-4 bg-transparent"
            onChange={ 
              (e) => {
                setReceiverBank(e.currentTarget.value)
              } 
            }
          />
          <input
            required
            type="text"
            min="0"
            placeholder="Cuenta"
            className="small w-100 px-2 py-2 border rounded-4 bg-transparent"
            onChange={ 
              (e) => {
                setReceiverAccount(e.currentTarget.value)
              } 
            }
          />
        </div>
      </div>
      <div>
        <small>Detalle </small>
        <div >
          <textarea readOnly id="detail" required name="detail" rows="5" cols="33" className="w-100 px-2 py-2 border rounded-4 bg-transparent fw-light fst-italic"
            value={`Remitente: ${senderBank} - ${senderAccount},\nDestinatario: ${receiverBank} - ${receiverAccount},\nPorcentaje aplicado: ${percentage}%,\nValor estimado: ${new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback*percentage/100)},\nValor real enviado: ${new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(sentValue)}`}>
          </textarea>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex gap-2">
          <div
            className="btn btn-light btn-sm rounded-pill border px-3"
            onClick={() => {
              off()
              setElement()
            }}
          >
            Cancelar
          </div>
          <button
            type="submit"
            name="submit"
            className="btn btn-dark bg-black btn-sm rounded-pill px-3"
          >
            Registrar
          </button>
        </div>
      </div>
    </form>
  </>

  return (
    <>
      <Modal element={element}/>
      <section className="pt-5 my-5 container d-grid gap-3">
        <div className="row">
          <Link to="/admin/riders" className="text-black display-1 d-flex justify-content-end" style={{marginLeft:"-2vh"}}><GrFormPrevious /></Link>
          <h2><small className="bg-black text-white rounded-pill h4 px-2">{user?.id}</small> {user?.firstName} {user?.lastName}</h2>
          <small>{user?.email} - {user?.mobileNumber} </small>
          <hr></hr>
          <small className="small">Porcentaje devolución</small>
          <h4 title="Pagos recibidos en carreras desde la última devolución">{percentage}%</h4>
          <small className="small">Periodo de tiempo</small>
          <small className="small"><b>Desde {formatDate(time?.start)} <br/> Hasta {formatDate(time?.end)} </b></small>
          <br/>
          <br/>
          
          <small className="small">Aplica sobre</small>
          <h4 title="Pagos recibidos en carreras desde la última devolución">{new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback)}</h4>
          <small className="small">Valor devolución</small>
          <h1 title="Pagos recibidos en carreras desde la última devolución">{new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback*percentage/100)}</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end gap-2">
            <button
              className="btn btn-light border rounded-pill btn-sm px-3"
              onClick={() => {
                selectUser()
                on()
              }}
            >
              Ver información de usuario
            </button>
            <button
              className="btn btn-dark bg-black rounded-pill btn-sm px-3"
              onClick={() => {
                setElement(elementCreate)
                on()
              }}
            >
              Registrar Devolución
            </button>
          </div>
        </div>
        <div className="row d-grid gap-2">
          <Transactions dataComponent={dataTransactions} getInitialsMain={getInitials}/>
        </div>
        <div className="row">
          <Requests dataComponent={dataRequests} />
        </div>
      </section>
    </>
  )
}

export default Page
