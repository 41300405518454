import { saveAs } from 'file-saver'
import * as XLSX from "xlsx"
import { toast } from 'sonner'
import { getRiders, getAllRiders } from '../../../services/functions'

export default function Component() {  

  const exportRidersToExcel = async  () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("¿Desea descargar la lista de usuarios?")) {
      document.getElementById("btnDownloadRiders").disabled = true
      toast("Esto puede tomar unos segundos, por favor espere la descarga")
  
      let dataAux = await getRiders()

      const worksheet = XLSX.utils.json_to_sheet(dataAux)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      saveAs(blob, `obiiUsuarios.xlsx`)
      setTimeout(() => {
        document.getElementById("btnDownloadRiders").disabled = false
      }, 3000)
    }
  }

  const exportAllRidersToExcel = async  () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("¿Desea descargar la lista de usuarios y carreras?")) {
      document.getElementById("btnDownloadAllRiders").disabled = true
      toast("Esto puede tomar unos segundos, por favor espere la descarga")
  
      let dataAux = await getAllRiders()

      const worksheet = XLSX.utils.json_to_sheet(dataAux)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      saveAs(blob, `obiiUsuarios&Carreras.xlsx`)
      setTimeout(() => {
        document.getElementById("btnDownloadAllRiders").disabled = false
      }, 3000)
    }
  }

  return (
    <div className="d-grid gap-2">
      <button
        onClick={() => exportRidersToExcel()}
        id="btnDownloadRiders"
        className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
      >
          Exportar usuarios
      </button>

      <button
        onClick={() => exportAllRidersToExcel()}
        id="btnDownloadAllRiders"
        className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
      >
          Exportar usuarios y carreras
      </button>
    </div>
  )
}