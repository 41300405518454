
export function on() {
  let space = document.getElementsByClassName('space')[0]
  space.classList.remove("invisible")
  space.classList.add("visible")
  document.body.style.overflow = "hidden"
}
  
export function off() {
  let space = document.getElementsByClassName('space')[0]
  space.classList.remove("visible")
  space.classList.add("invisible")
  document.body.style.overflow = "auto"
}