import React from 'react'
import { Auth } from "../../context/AuthContext"
import { FaChevronUp } from "react-icons/fa6"

export default function Component() {
  const { DRVRSR, signOutDriver } = Auth()
  return (
    <>
      <nav className="navbar navbar-expand-lg pt-lg-3 py-2 font-a fixed-top bg-light z-1">
        <div className="container">
          <div className="navbar-brand text-center">
            <div className="icon-dash"></div>
            <span className="h6">Conductor</span>
          </div>
          <div className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="menu"></span>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="nav">
              <li className="nav-item" title={DRVRSR?.firstName}  onClick={() => signOutDriver()}>
                <span>Cerrar sesión</span>
              </li>
              <li className="nav-item w-100 text-center">
                <div className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FaChevronUp className="display-1 text-black"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}