import { useState, useEffect } from "react"
import { toast } from 'sonner'
import { getPercentage, updatePercentage, getTime, updateTime } from "../../services/options"
import { on, off } from "../../utils/onOff"
import Modal from '../../components/Modal'


function Page() {
  const [ element, setElement ] = useState([])
  const [ percentage, setPercentage ] = useState()
  const [ time, setTime ] = useState()


  useEffect(() => {
    
    getInitials()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const options = {
    year: "2-digit",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    weekday: "long",
    hour12: true,
    timeZone: 'America/New_York'
  }

  const formatDate = (dateAux) => {
    if(dateAux) {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }
  }

  const getInitials = async () => {    
    getPercentage()
    .then((result) => {
      setPercentage(result.value)
    })
    .catch((e) => {
      console.error(e)
    })

    getTime()
    .then((result) => {
      setTime(result)
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  //Update

  const validateUpdatePercentage = async (e) => {    
    e.preventDefault()

    let newPercentage = e.target.newPercentage.value
  
    let body = {
      value: Number(newPercentage)
    }
    updatePercentage({body})
    .then(() => {
      toast.success("Porcentaje actualizado")
      getInitials()
      off()
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  const validateUpdateTime = async (e) => {    
    e.preventDefault()

    let start = e.target.start.value
    start = new Date(start)
    let end = e.target.end.value
    end = new Date(end)
  
    let body = {
      start: start.toISOString(),
      end: end.toISOString()
    }

    updateTime({body})
    .then(() => {
      toast.success("Periodo actualizado")
      getInitials()
      off()
    })
    .catch((e) => {
      console.error(e)
    })
  }  


  const selectPercentage = async () => {    
    setElement(
      <>
        <form className="d-grid gap-3" onSubmit={(e) => validateUpdatePercentage(e)}>
          <div>
            <h2 className=" text-2xl font-extrabold">Actualizar porcentaje</h2>
          </div>
          <small className="text-zinc-400">Porcentaje actual: <b>{percentage}%</b></small>
          <div>
            <small> Nuevo porcentaje general </small>
            <div >
              <input  step="0.01" min="0" required id="newPercentage" name="newPercentage" type="number" defaultValue={percentage} className="w-100 px-2 py-2 border rounded-4 bg-transparent"/>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="d-flex gap-2">
              <div
                className="btn btn-light btn-sm rounded-pill border px-3"
                onClick={() => {
                  off()
                  setElement()
                }}
              >
                Cancelar
              </div>
              <button
                type="submit"
                name="submit"
                className="btn btn-dark bg-black btn-sm rounded-pill px-3"
              >
                Actualizar
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }  

  const selectTime = async () => {    
    setElement(
      <>
        <form className="d-grid gap-3" onSubmit={(e) => validateUpdateTime(e)}>
          <div>
            <h2 className=" text-2xl font-extrabold">Actualizar Periodo</h2>
          </div>
          <div className="d-grid">
            <small className="text-zinc-400">Fecha de inicio actual: <b>{formatDate(time?.start)}</b></small>
            <small className="text-zinc-400">Fecha final actual: <b>{formatDate(time?.end)}</b></small>
          </div>
          <div>
            <small> Nueva fecha de inicio </small>
            <div >
              <input required id="start" name="start" type="datetime-local" defaultValue={time?.start} className="w-100 px-2 py-2 border rounded-4 bg-transparent"/>
            </div>
          </div>
          <div>
            <small> Nueva fecha final </small>
            <div >
              <input required id="end" name="end" type="datetime-local" defaultValue={time?.end} className="w-100 px-2 py-2 border rounded-4 bg-transparent"/>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <div className="d-flex gap-2">
              <div
                className="btn btn-light btn-sm rounded-pill border px-3"
                onClick={() => {
                  off()
                  setElement()
                }}
              >
                Cancelar
              </div>
              <button
                type="submit"
                name="submit"
                className="btn btn-dark bg-black btn-sm rounded-pill px-3"
              >
                Actualizar
              </button>
            </div>
          </div>
        </form>
      </>
    )
  }  


  return (
    <>
      <Modal element={element}/>
      <section className="pt-5 my-5 container d-grid gap-3">
        <div className="row">
          <h1 className="mt-3 mt-sm-5 text-center">Opciones de configuración</h1>
        </div>
        <hr></hr>
        <div className="row">
          <div className="d-grid gap-3">
            <div className="d-grid">
              <small className="lead">Porcentaje general de devolución</small>
                <div >
                  <b className="h3">{percentage}%</b>
                </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
                onClick={() => {
                  selectPercentage()
                  on()
                }}
              >
                Editar porcentaje
              </button>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="row">
          <div className="d-grid gap-3">
            <div className="d-grid">
              <small className="lead">Periodo aplicado a devoluciones</small>
              <div >
                <small className="text-zinc-400">Fecha de inicio actual: <b>{formatDate(time?.start)}</b></small>
                <br/>
                <small className="text-zinc-400">Fecha final actual: <b>{formatDate(time?.end)}</b></small>
              </div>
            </div>
            <div className="d-flex">
              <button
                className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
                onClick={() => {
                  selectTime()
                  on()
                }}
              >
                Editar periodo
              </button>
            </div>
          </div>
        </div>
        <hr></hr>
      </section>
    </>
  )
}

export default Page
