import PropTypes from 'prop-types'
import { IoMdClose } from "react-icons/io"
import { off } from "../utils/onOff"

export default function Component({element}) {
  return (
    <>
      <div className="position-fixed invisible space z-3 d-flex justify-content-center top-0 left-0 bg-black bg-opacity-75 h-100 w-100 overflow-auto">
        <div className="w-100">
          <div className="btn btn-danger p-1 rounded-pill position-absolute top-0 end-0 mt-3 me-3 z-3">
            <IoMdClose
              className="display-4"
              onClick={() => off()}
            />
          </div>

        </div>
        <div className="bg p-4 p-sm-5 mx-3 rounded-5 position-absolute" style={{marginTop:"10vh"}}>
          {element}
        </div>        
      </div>
    </>
  )
}

Component.propTypes = {
  element: PropTypes.any
}
