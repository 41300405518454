import React, { useState, useEffect } from 'react'
import { Auth } from "../../context/AuthContext"
import { FaChevronUp } from "react-icons/fa6"
import { Link, useLocation } from 'react-router-dom'

export default function Component() {
  const { user, googleSignOut } = Auth()
  let location = useLocation()
  const [active, setActive] = useState()

  useEffect(() => {
    if(location.pathname === "/admin") setActive('#drivers')
    else if(location.pathname === "/admin/drivers") setActive('#drivers')
    else if(location.pathname === "/admin/riders") setActive('#riders')
    else if(location.pathname === "/admin/transactions") setActive('#transactions')
    else if(location.pathname === "/admin/admins") setActive('#admins')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <>
      <nav className="navbar navbar-expand-lg pt-lg-3 py-2 font-a fixed-top bg-light z-1">
        <div className="container">
          <div className="navbar-brand text-center">
            <div className="icon-dash"></div>
            <span className="h6">Administrador</span>
          </div>
          <div className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="menu"></span>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul className="nav">
              <li className="nav-item">
                <Link to="/admin/drivers" onClick={() => setActive('#drivers')} className={active === '#drivers' ? 'active' : ''} >Conductores</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/riders" onClick={() => setActive('#riders')} className={active === '#riders' ? 'active' : ''} >Pasajeros</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/transactions" onClick={() => setActive('#transactions')} className={active === '#transactions' ? 'active' : ''} >Transacciones</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/admins" onClick={() => setActive('#admins')} className={active === '#admins' ? 'active' : ''} >Admins</Link>
              </li>
              <li className="nav-item">
                <Link to="/admin/options" onClick={() => setActive('#options')} className={active === '#options' ? 'active' : ''} >Opciones</Link>
              </li>
              <li className="nav-item" title={user?.email}  onClick={() => googleSignOut()}>
                <span>Cerrar sesión</span>
              </li>
              <li className="nav-item w-100 text-center">
                <div className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <FaChevronUp className="display-1 text-black"/>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}