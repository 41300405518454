import PropTypes from 'prop-types'
import { GrPrevious, GrNext  } from "react-icons/gr"

export default function Component({ previous, next, page, count, numberDocs }) {

  return (
    <>
    
      <div className="col-12">
        <div className="d-grid d-sm-flex justify-content-sm-end gap-2 gap-sm-5">
          <div className="d-flex gap-3">
            <span className="small">Página {page}</span>
            <div className="d-flex gap-3">
              {
                page > 1 ? <GrPrevious className="cursor-pointer" onClick={ () => previous() } />
                : <GrPrevious className="cursor-pointer opacity-25"/>                
              }

              {
                page < Math.ceil(count/numberDocs) ? <GrNext className="cursor-pointer" onClick={ () => next() } />
                : <GrNext className="cursor-pointer opacity-25" />
              }  
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Component.propTypes = {
  previous: PropTypes.func,
  next: PropTypes.func,
  page: PropTypes.number,
  numberDocs: PropTypes.number,
  count: PropTypes.number
};
