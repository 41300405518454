import { useState, useEffect } from "react"
import { toast } from 'sonner'
import { getAll, create, update, del } from "../../services/admins"
import { on, off } from "../../utils/onOff"

import Filters from "../../components/Admin/Admins/Filters"
import Table from "../../components/Admin/Admins/Table"
import Modal from '../../components/Modal'


function Page() {
  const [ element, setElement ] = useState([])
  const [ data, setData ] = useState([])
  const [ count, setCount ] = useState(0)
  const [ dataPage, setDataPage ] = useState([])
  const [ page, setPage ] = useState(1)
  const numberDocs = 10

  const filterData = async (array, arrayLenght, newPage) => { 

    const newData = []
    let start = ((newPage-1)*numberDocs)
    let end = arrayLenght
    if(((newPage)*numberDocs) < end) end = ((newPage)*numberDocs)
    for (let index = start; index < end; index++) {   
      if(array[index] !== undefined) newData.push(array[index])
    }
    setPage(newPage)  
    return newData
  }


  useEffect(() => {
    
    getInitials()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getInitials = async () => {    
    getAll()
    .then((result) => {
      setCount(result.countDocs)
      setData(result.data)
      filterData(result.data, result.countDocs, 1 )
      .then((res) => setDataPage(res))
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  const next = async () => {    
    filterData(data, count, page + 1)
    .then((res) => setDataPage(res))
  }

  const previous = async () => {    
    filterData(data, count, page -1)
    .then((res) => setDataPage(res))
  }  

  //Create

  const validateCreate = async (e) => {    
    e.preventDefault()
    let validate = true
    let email = e.target.email.value
    if(email!== "" && email.includes("gmail") === true) {
      data.forEach(doc => {
        if(doc.email === email) validate = false
      })
      if(validate) {
        let body = {
          email: email
        }
        create(body)
        .then(() => {
          e.target.email.value = ""
          toast.success("usuario creado")
          getInitials()
          off()
        })
        .catch((e) => {
          console.error(e)
        })
      } else {
        toast.error("El correo ya existe como administrador")
      }
    } else toast.warning("El correo debe ser Gmail")
  }  

  const elementCreate = <>
    <form className="d-grid gap-3" onSubmit={(e) => validateCreate(e)}>
      <div>
        <h2 className=" text-2xl font-extrabold text-zinc-800">Crear administrador</h2>
      </div>
      <small >*El correo electrónico debe ser Gmail</small>
      <div>
        <small> Correo electrónico </small>
        <div >
          <input required id="email" name="email" type="email" autoComplete="email" className="w-100 px-2 py-2 border rounded-4 bg-transparent"/>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-flex gap-2">
          <div
            className="btn btn-light btn-sm rounded-pill border px-3"
            onClick={() => {
              off()
              setElement()
            }}
          >
            Cancelar
          </div>
          <button
            type="submit"
            name="submit"
            className="btn btn-dark bg-black btn-sm rounded-pill px-3"
          >
            Crear
          </button>
        </div>
      </div>
    </form>
  </>

  //Update

  const validateUpdate = async (e, id, oldEmail) => {    
    e.preventDefault()
    let validate = true
    let email = e.target.email.value
    if(email!== "" && email.includes("gmail") === true) {
      data.forEach(doc => {
        if(doc.email === email && email !== oldEmail) validate = false
      })
      if(validate) {
        let body = {
          email: email
        }
        update({id,body})
        .then(() => {
          toast.success("usuario actualizado")
          e.target.email.value = ""
          getInitials()
          off()
        })
        .catch((e) => {
          console.error(e)
        })
      } else {
        toast.error("El correo ya existe como administrador")
      }
    } else toast.warning("El correo debe ser Gmail")
  }  

  const selectUser = async (id) => {    
    if(id !== "" && id != null) {
      let newData = data.filter(doc => doc.id === id )
      setElement(
        <>
          <form className="d-grid gap-3" onSubmit={(e) => validateUpdate(e, id, newData[0].email)}>
            <div>
              <h2 className=" text-2xl font-extrabold">Actualizar administrador</h2>
            </div>
            <small><b>{newData[0].email}</b></small>
            <small className="text-zinc-400">*El correo electrónico debe ser Gmail</small>
            <div>
              <small> Correo electrónico </small>
              <div >
                <input required id="email" name="email" type="email" defaultValue={newData[0].email} autoComplete="email" className="w-100 px-2 py-2 border rounded-4 bg-transparent"/>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="d-flex gap-2">
                <div
                  className="btn btn-light btn-sm rounded-pill border px-3"
                  onClick={() => {
                    off()
                    setElement()
                  }}
                >
                  Cancelar
                </div>
                <button
                  type="submit"
                  name="submit"
                  className="btn btn-dark bg-black btn-sm rounded-pill px-3"
                >
                  Actualizar
                </button>
              </div>
            </div>
          </form>
        </>
      )
    } else toast.error("Usuario indefinido")
  }  

  //Delete

  const validateDelete = async (id) => {   
    if(data.length > 1) {
      // eslint-disable-next-line no-restricted-globals
      if ( confirm("¿Está seguro de eliminar este usuario?")) {
        del({id})
        .then(() => {
          toast.success("usuario Eliminado")
          getInitials()
        })
        .catch((e) => {
          console.error(e)
        })
      }
    }
    else toast.warning("No puede eliminar todos los usuarios administradores")
  }  


  return (
    <>
      <Modal element={element}/>
      <section className="pt-5 my-5 container d-grid gap-3">
        <div className="row">
          <h1 className="mt-3 mt-sm-5 text-center">Administradores</h1>
        </div>
        <div className="row">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
              onClick={() => {
                setElement(elementCreate)
                on()
              }}
            >
              Crear nuevo administrador
            </button>
          </div>
        </div>
        <div className="row">
          <Filters next={next} previous={previous} page={page} numberDocs={numberDocs} count={count} />
        </div>
        <div className="row">
          <Table dataPage={dataPage}  on={on} selectUser={selectUser} validateDelete={validateDelete} />
        </div>
      </section>
    </>
  )
}

export default Page
