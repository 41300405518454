/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import Footer from "../../components/Footer"
import { useTranslation } from 'react-i18next'
import { IoLogoWhatsapp } from "react-icons/io"
import { toast } from 'sonner'
import { getByUser } from "../../services/riders"
import { Auth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

function App() {
  const [t] = useTranslation('global')
  const { signInRider, RDRSR } = Auth()
  const navigate = useNavigate() 

  const validateLogin = async (e) => {    
    e.preventDefault()
    let number = `57${e.target.number.value}`
    getByUser(number)
    .then((result) => {
      if(Object.keys(result).length > 0) {
        signInRider({user : result})
      } else {
        toast.warning("Usuario no registrado, si está usando 57 al inicio por favor omítalo")
      }
    })
    .catch((e) => {
      console.error(e)
    })
  }  

  useEffect(() => {
    if (RDRSR) {
      navigate("/rider")
    }
  }) 

  return (
    <div className="bg-black">
      <section className="bg-login-rider">
      <h1 className="position-absolute text-white top-0 m-2 m-lg-5 display-3 ">USUARIO</h1>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-4 offset-lg-4 text-white bg-dark bg-opacity-75 text-center p-5 rounded-5">
              <div className="logo-light"></div>
              <form className="d-grid gap-3" onSubmit={(e) => validateLogin(e)}>
                <div>
                  <div >
                    <input required id="number" name="number" type="text" className="w-100 px-3 py-3 border rounded-5 bg-black border-0 text-white" placeholder="Número de celular "/>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="d-flex gap-2">
                    <button
                      type="submit"
                      name="submit"
                      className="btn btn-dark bg-black rounded-pill px-5 fw-bolder py-3 fs-5 border-0"
                    >
                      Iniciar sesión
                    </button>
                  </div>
                </div>
                <div className="d-flex ">
                  <small className="d-grid gap-1 fw-light text-start">
                    <Link to="/" className="text-white text-decoration-none">Ir a la página principal</Link>
                  </small>
                </div>
              </form>           
            </div>
          </div>
        </div>
      </section>
      
      <a className="wtp-aux" href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
        {t("support")}
        <IoLogoWhatsapp className="wtp-icon"/>
      </a>

      <Footer/>
    </div>
  );
}

export default App;
