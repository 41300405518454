import PropTypes from 'prop-types'

export default function Component({ dataPage, selectUser, validateDelete, on }) {
  if(dataPage.length > 0) {

    return (
      <>
       <div className="mb-5 table-responsive">
          <table className="table border ">
            <thead className="text-uppercase small">
              <tr>
                <th scope="col" className="px-3 py-3 ">
                  Correo
                </th>
                <th scope="col" className="px-3 py-3">
                  Opciones
                </th>
              </tr>
            </thead>
              <tbody>
                {dataPage.map(doc =>
                  <tr key={doc.id}>
                    <td className="px-3 py-3">
                      <small>{doc.email} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <div className="d-flex gap-2">

                        <div
                          className="btn btn-sm bg-white shadow-sm cursor-pointer px-3 fw-bold rounded-pill"
                          onClick={() => {
                            selectUser(doc.id)
                            on()
                          }}
                        >
                          Editar
                        </div>

                        <div
                           className="btn btn-sm bg-white shadow-sm cursor-pointer px-3 fw-bold rounded-pill"
                           onClick={() => {
                             validateDelete(doc.id)
                           }}
                        >
                          Eliminar
                        </div>
                      </div>
                    </td>                
                  </tr>
                )}

              </tbody>
          </table>
        </div>
      </>
    )
  } else {
    
    return (
      <>
        <hr />
      </>
    )
  } 
}

Component.propTypes = {
  dataPage: PropTypes.array,
  selectUser: PropTypes.func,
  validateDelete: PropTypes.func,
  on: PropTypes.func,
}
