const URL_API = process.env.REACT_APP_URL_API
const TOKEN = process.env.REACT_APP_TOKEN

export const getPercentage = async ()  => {
    let data = []
    let url = `${URL_API}/options`
    await fetch(url.concat(`/percentage`),{
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const updatePercentage = async ({ body})  => {
    let url = `${URL_API}/options`
    await fetch(url.concat(`/percentage`), {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(body)
    })
}


export const getTime = async ()  => {
    let data = []
    let url = `${URL_API}/options`
    await fetch(url.concat(`/time`),{
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const updateTime = async ({ body})  => {
    let url = `${URL_API}/options`
    await fetch(url.concat(`/time`), {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(body)
    })
}
