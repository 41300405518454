
function Page() {

  return (
    <>
      <div style={{height: '100vh'}}>
        <div className="container h-100">
          <div className="d-flex h-100 align-items-center">
            <div className="col-12 text-center">
              <h1>ERROR 404</h1>
              <p className="lead">Página no encontrada</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Page
