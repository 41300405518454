import { useEffect} from 'react'
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from 'react-router-dom'
import { Auth } from "../../context/AuthContext"

const Login = () => {
  const {googleSignIn, user} = Auth()
  const navigate = useNavigate()  

  const handleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (user !== null) {
      navigate('/admin')
    }
  }) 
  
  return (
    <>
      <div style={{height: '100vh'}}>
        <div className="container h-100">
            <div  className="d-flex align-items-center h-100">
              <div className="col text-center">
                <p>Administrador por favor verifique su identidad e inicie sesión</p>
                <button className="btn btn-dark rounded-pill" onClick={() =>handleSignIn()}><FcGoogle className="text-2xl inline me-1"/>Ingresar con Google</button>  
              </div>                          
            </div>
          </div>
      </div>
    </>
  )
}

export default Login