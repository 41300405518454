const URL_API = process.env.REACT_APP_URL_API
const TOKEN = process.env.REACT_APP_TOKEN

export const getAll = async ()  => {
    let data = []
    let url = `${URL_API}/drivers`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getById = async (id)  => {
    let data = {}
    let url = `${URL_API}/drivers/id/${id}`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getByUser = async (user)  => {
    let data = {}
    let url = `${URL_API}/drivers/user/${user}`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}
