
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyAoJH5J_yQ-eLXdjHAMhanMIdHq6_l9cTA",
  authDomain: "obii-fd223.firebaseapp.com",
  projectId: "obii-fd223",
  storageBucket: "obii-fd223.appspot.com",
  messagingSenderId: "355482844150",
  appId: "1:355482844150:web:27cf1713e284fadd3ba5d7",
  measurementId: "G-TG2ZTP342L"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const analytics = getAnalytics(app)