import { IoLogoWhatsapp } from "react-icons/io"
import { IoLogoFacebook } from "react-icons/io5"
import { BiLogoInstagramAlt } from "react-icons/bi"
import { BsYoutube } from "react-icons/bs"
import { useTranslation } from 'react-i18next'

// eslint-disable-next-line react/prop-types
function Component() {
  const date = new Date()
  const [t] = useTranslation('global')

  return (
    <>
      <footer>
        <div className="container mt-5">
          <div className="row text-white">
            <div className="col-12 col-lg-4 text-center">
              <div className="logo-footer"></div>
              <p><a href='https://sites.google.com/view/obii-tec/p%C3%A1gina-principal' target='_blank' rel="noreferrer" className="small text-white text-decoration-none">{t("footer-terms")}</a></p>
              <p><a href='https://sites.google.com/view/obii/p%C3%A1gina-principal' target='_blank' rel="noreferrer" className="small text-white text-decoration-none">{t("footer-privacy")}</a></p>                
            </div>
            <div className="col-12 col-lg-4 text-center my-3 my-lg-0">
              <a href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
                <IoLogoWhatsapp className="icon-white"/>
              </a>
              <a href='https://www.facebook.com/share/97zTSxY5W9FSonF8/?mibextid=qi2Omg' target='_blank' rel="noreferrer">
                <IoLogoFacebook className="icon-white"/>
              </a>
              <a href='https://www.instagram.com/obii_col?igsh=YXhjN2NrZWV6MGJu' target='_blank' rel="noreferrer">
                <BiLogoInstagramAlt className="icon-white"/>
              </a>
              <a href='https://youtube.com/@obiitechnology9926?si=7sbbx4sC32asK1_q' target='_blank' rel="noreferrer">
                <BsYoutube className="icon-white"/>
              </a>
            </div>
            <div className="col-12 col-lg-4 text-center my-3 my-lg-0">
              <div className='d-flex gap-5 justify-content-center'>
              <a href='#downloads'>
                <div className="logo-rider-sm"><span className="subtext-white">{t("rider")}</span></div>
              </a>
              <a href='#downloads'>
                <div className="logo-driver-sm"><span className="subtext-white">{t("driver")}</span></div>
              </a>
              </div>
          
            </div>

            <hr/>
            <div className="col-12 p-3 p-lg-0">
              <p className="small text-light">{t("footer-detail")}</p>                
              <p className="small text-light text-center">{t("footer-copyright")} {date.getFullYear()}</p>                
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Component