import { saveAs } from 'file-saver'
import * as XLSX from "xlsx"
import { toast } from 'sonner'
import { getDrivers, getAllDrivers } from '../../../services/functions'

export default function Component() {  

  const exportDriversToExcel = async  () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("¿Desea descargar la lista de conductores?")) {
      document.getElementById("btnDownloadDrivers").disabled = true
      toast("Esto puede tomar unos segundos, por favor espere la descarga")
  
      let dataAux = await getDrivers()

      const worksheet = XLSX.utils.json_to_sheet(dataAux)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      saveAs(blob, `obiiConductores.xlsx`)
      setTimeout(() => {
        document.getElementById("btnDownloadDrivers").disabled = false
      }, 3000)
    }
  }

  const exportAllDriversToExcel = async  () => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("¿Desea descargar la lista de conductores y carreras?")) {
      document.getElementById("btnDownloadAllDrivers").disabled = true
      toast("Esto puede tomar unos segundos, por favor espere la descarga")
  
      let dataAux = await getAllDrivers()

      const worksheet = XLSX.utils.json_to_sheet(dataAux)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([excelBuffer], {type: 'application/octet-stream'})
      saveAs(blob, `obiiConductores&Carreras.xlsx`)
      setTimeout(() => {
        document.getElementById("btnDownloadAllDrivers").disabled = false
      }, 3000)
    }
  }

  return (
    <div className="d-grid gap-2">
      <button
        onClick={() => exportDriversToExcel()}
        id="btnDownloadDrivers"
        className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
      >
          Exportar conductores
      </button>

      <button
        onClick={() => exportAllDriversToExcel()}
        id="btnDownloadAllDrivers"
        className="btn btn-dark bg-black rounded-pill btn-sm px-3 shadow"
      >
          Exportar conductores y carreras
      </button>
    </div>
  )
}