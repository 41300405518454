const URL_API = process.env.REACT_APP_URL_API
const TOKEN = process.env.REACT_APP_TOKEN

export const getDrivers = async ()  => {
    let data = []
    let url = `${URL_API}/functions/drivers`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getAllDrivers = async ()  => {
    let data = []
    let url = `${URL_API}/functions/allDrivers`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getRiders = async ()  => {
    let data = []
    let url = `${URL_API}/functions/riders`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getAllRiders = async ()  => {
    let data = []
    let url = `${URL_API}/functions/allRiders`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}