const URL_API = process.env.REACT_APP_URL_API
const TOKEN = process.env.REACT_APP_TOKEN

export const getAll = async ()  => {
    let data = []
    let url = `${URL_API}/requests`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getByDriver = async (driverId)  => {
    let data = []
    let url = `${URL_API}/requests/driver/${driverId}`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const getByRider = async (riderId)  => {
    let data = []
    let url = `${URL_API}/requests/rider/${riderId}`
    await fetch(url, {
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}
