import PropTypes from 'prop-types'
import { useState } from "react"
import { GrPrevious, GrNext  } from "react-icons/gr"
import { IoSearch } from "react-icons/io5"
import { TbReload } from "react-icons/tb"
import { toast } from 'sonner'

export default function Component({ searchBy, previous, next, page, count, numberDocs }) {
  const [ search, setSearch ] = useState()
  const [ by, setBy ] = useState("user")

  return (
    <>
      <div className="col-12">
        <div className="d-flex gap-2">
            <div className="d-grid text-center">
              <span className="small">Buscar por</span>            
              <select
                className="rounded border small bg-transparent"
                value={by}
                onChange={ 
                  (e) => {
                    setBy(e.currentTarget.value)
                  } 
                }
              >
                <option value="user">ID Usuario</option>
              </select>
            </div>
              <input
                type="text"
                id="search"
                className="rounded-3 border bg-transparent"
                onChange={ (e) => setSearch(e.currentTarget.value) }
              />
              <button
                type="button"
                onClick={ () => {
                  searchBy(search, by)
                }} 
                className="btn btn-light px-2 border rounded-3"
                title="Buscar"
              >
                <IoSearch />
              </button>
              <div className="btn btn-light px-2 border rounded-3" title="Recargar">
                <TbReload
                  onClick={() => {
                    toast("Limpiando", {duration:700})
                    searchBy(null)
                    document.getElementById("search").value=""
                  }} 
                />
              </div>
          
        </div>
      </div>

      <div className="col-12">
        <div className="d-grid d-sm-flex justify-content-sm-end gap-2 gap-sm-5">
          <div className="d-flex gap-3">
            <span className="small">Página {page}</span>
            <div className="d-flex gap-3">
              {
                page > 1 ? <GrPrevious className="cursor-pointer" onClick={ () => previous() } />
                : <GrPrevious className="cursor-pointer opacity-25"/>                
              }

              {
                page < Math.ceil(count/numberDocs) ? <GrNext className="cursor-pointer" onClick={ () => next() } />
                : <GrNext className="cursor-pointer opacity-25" />
              }  
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Component.propTypes = {
  searchBy: PropTypes.func,
  previous: PropTypes.func,
  next: PropTypes.func,
  page: PropTypes.number,
  numberDocs: PropTypes.number,
  count: PropTypes.number
};
