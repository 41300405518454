import PropTypes from 'prop-types'
import { HiOutlineUser  } from "react-icons/hi2"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { Link } from "react-router-dom"
import { del} from "../../../services/transactions"
import { toast } from 'sonner'


export default function Component({ dataPage, getInitials }) {
  if(dataPage.length > 0) {

    const options = {
      year: "2-digit",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      weekday: "long",
      hour12: true,
      timeZone: 'America/New_York'
    }

    const formatDate = (dateAux) => {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }

    const ValidateDelete = (id) => {
      if(id) {
      // eslint-disable-next-line no-restricted-globals
        if(confirm(`¿Está seguro de eliminar este registro?`)){
          let validate = prompt("Ingrese la palabra 'delete' para continuar")
          if(validate === "delete") {
            del({id})
            setTimeout(() => {
              getInitials()
            }, 300);
          } else toast("Palabra incorrecta")
        }
      } else toast("ID indefinido")
      
    }

    return (
      <>
       <div className="mb-5 table-responsive">
          <table className="table table-hover border">
            <thead className="text-uppercase small ">
              <tr>
                <th scope="col" className="px-3 py-3 ">
                  tipo
                </th>
                <th scope="col" className="px-3 py-3">
                  rol
                </th>
                <th scope="col" className="px-3 py-3">
                  valor
                </th>
                <th scope="col" className="px-3 py-3">
                  estado
                </th>
                <th scope="col" className="px-3 py-3">
                  detalle
                </th>
                <th scope="col" className="px-3 py-3">
                  fecha
                </th>
                <th scope="col" className="px-3 py-3 text-nowrap">
                  Usuario
                </th>
                <th scope="col" className="px-3 py-3 text-nowrap">
                  Opciones
                </th>
              </tr>
            </thead>
              <tbody>
                {dataPage.map(doc =>
                  <tr key={doc.id}>
                    <td className="px-3 py-3">
                      <small>{doc.type} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.rol} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.value} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <small>{doc.status} </small>
                    </td> 
                    <td className="px-3 py-3 fst-italic" style={{minWidth:'300px'}}>
                      <small>{doc.detail} </small>
                    </td> 
                    <td className="px-3 py-3" style={{minWidth:'200px'}}>
                      <small>{formatDate(doc.created)} </small>
                    </td> 
                    <td className="px-3 py-3">
                      <Link to={doc.rol === "driver"? `/admin/driver/${doc.user}` : `/admin/rider/${doc.user}`} className="d-flex gap-2 justify-content-center">
                        <HiOutlineUser className="h5 cursor-pointer text-black mt-3" />
                      </Link>
                    </td> 
                     <td className="px-3 py-3">
                        <div 
                          onClick={() => {
                            ValidateDelete(doc.id)
                          }} 
                          className="d-flex justify-content-center"
                        >
                          <IoIosCloseCircleOutline className="text-danger h5 cursor-pointer mt-3" />
                        </div>
                      </td>                                      
                  </tr>
                )}

              </tbody>
          </table>
        </div>
      </>
    )
  } else {
    
    return (
      <>
        <hr />
      </>
    )
  } 
}

Component.propTypes = {
  dataPage: PropTypes.array,
  getInitials: PropTypes.func
}
