import { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import { toast } from 'sonner'

import Filters from "./Requests/FiltersUser"
import Table from "./Requests/TableUser"


export default function Component({dataComponent}) {
  const [ initialData, setInitialData ] = useState([])
  const [ initialCount, setInitialCount ] = useState(0)
  const [ data, setData ] = useState([])
  const [ count, setCount ] = useState(0)
  const [ dataPage, setDataPage ] = useState([])
  const [ page, setPage ] = useState(1)
  const numberDocs = 5

  const filterData = async (array, arrayLenght, newPage) => { 

    const newData = []
    let start = ((newPage-1)*numberDocs)
    let end = arrayLenght
    if(((newPage)*numberDocs) < end) end = ((newPage)*numberDocs)
    for (let index = start; index < end; index++) {   
      if(array[index] !== undefined) newData.push(array[index])
    }
    setPage(newPage)  
    return newData
  }


  useEffect(() => {
    
    getInitials()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComponent])

  const getInitials = async () => {    
    if(dataComponent.length > 0) {
      setInitialCount(dataComponent.length)
      setCount(dataComponent.length)
      setInitialData(dataComponent)
      setData(dataComponent)
      filterData(dataComponent, dataComponent.length, 1 )
      .then((res) => setDataPage(res))
    }
  }  

  const next = async () => {    
    filterData(data, count, page + 1)
    .then((res) => setDataPage(res))
  }

  const previous = async () => {    
    filterData(data, count, page -1)
    .then((res) => setDataPage(res))
  }  

  const searchBy = async (search, by) => {

    if(search !== "" && search != null) {
      toast("Buscando", {duration:700})
      let searchAux = search.trim().toLowerCase()
      if(by === "id" || by === "paidAmount") searchAux = Number(searchAux)
      // eslint-disable-next-line array-callback-return
      let newData = initialData.filter(function(obj) {
        if(by === "id" || by === "paidAmount") {
          return obj[by] === searchAux
        } else {
          if(obj[by] !== null) {
            return obj[by].trim().toLowerCase() === searchAux
          }
        }
      })
      setData(newData)
      setCount(newData.length)
      filterData(newData, newData.length, 1)
      .then((res) => setDataPage(res))
    } else {
      setData(initialData)
      setCount(initialCount)
      filterData(initialData, initialCount, 1)
      .then((res) => setDataPage(res))
    }
    
  }

  return (
    <>
      <section className=" container d-grid gap-3">
        <div className="row">
          <h2 className="text-center">Carreras</h2>
        </div>
        <div className="row d-grid gap-2">
          <Filters searchBy={searchBy} next={next} previous={previous} page={page} numberDocs={numberDocs} count={count} />
        </div>
        <div className="row">
          <Table dataPage={dataPage} />
        </div>
      </section>
    </>
  )
}

Component.propTypes = {
  data: PropTypes.array
}
