const URL_API = process.env.REACT_APP_URL_API
const TOKEN = process.env.REACT_APP_TOKEN


export const getAll = async ()  => {
    let data = []
    let url = `${URL_API}/admins`
    await fetch(url,{
        headers: { Authorization: `Bearer ${TOKEN}`}
    })
    .then((res) => res.json())
    .then((result) => {
        data = result
    })
    return data
}

export const create = async (json)  => {
    let url = `${URL_API}/admins`
    await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(json)
    })
}

export const update = async ({id, body})  => {
    let url = `${URL_API}/admins`
    await fetch(url.concat(`/${id}`), {
        method: "PATCH",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(body)
    })
}


export const del = async ({id})  => {
    let url = `${URL_API}/admins`
    await fetch(url.concat(`/${id}`), {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Authorization": `Bearer ${TOKEN}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer"
    })
}
