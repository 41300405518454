import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Toaster } from 'sonner'

//context
import { AuthContextProvider } from './context/AuthContext'
import { DataProvider } from './context/DataProvider'

//layouts
import LayoutAdmin from './layouts/LayoutAdmin'
import LayoutDriver from './layouts/LayoutDriver'
import LayoutRider from './layouts/LayoutRider'

//pages landing
import Landing from './pages/landing/Landing'

//pages auth
import Error404 from './pages/auth/Error404'
import AdminAuth from './pages/auth/LoginAdmin'
import DriverAuth from './pages/auth/LoginDriver'
import RiderAuth from './pages/auth/LoginRider'

//pages admin
import AdminAdmins from './pages/admin/Admins'
import AdminOptions from './pages/admin/Options'
import AdminDrivers from './pages/admin/Drivers'
import AdminDriver from './pages/admin/Driver'
import AdminRiders from './pages/admin/Riders'
import AdminRider from './pages/admin/Rider'
import AdminTransactions from './pages/admin/Transactions'

//pages driver
import DriverHome from './pages/driver/Driver'

//pages rider
import RiderHome from './pages/rider/Rider'


function App() {

  return (
      <DataProvider>
        <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={<Landing/>} ></Route>
              <Route path='/adminAuth' element={<AdminAuth/>} ></Route>
              <Route path='/driverAuth' element={<DriverAuth/>} ></Route>
              <Route path='/riderAuth' element={<RiderAuth/>} ></Route>

              <Route path='/admin' element={<LayoutAdmin/>} >
                <Route index element={<AdminDrivers/>} />
                <Route path='admins' element={<AdminAdmins/>} />
                <Route path='options' element={<AdminOptions/>} />
                <Route path='drivers' element={<AdminDrivers/>} />
                <Route path='driver/:id' element={<AdminDriver/>} />
                <Route path='riders' element={<AdminRiders/>} />
                <Route path='rider/:id' element={<AdminRider/>} />
                <Route path='transactions' element={<AdminTransactions/>} />
              </Route>

              <Route path='/driver' element={<LayoutDriver/>} >
                <Route index element={<DriverHome/>} />
                <Route path='home' element={<DriverHome/>} />
              </Route>

              <Route path='/rider' element={<LayoutRider/>} >
                <Route index element={<RiderHome/>} />
                <Route path='home' element={<RiderHome/>} />
              </Route>

              <Route path='*' element={<Error404/>} />
            </Routes>
            <Toaster />
          </BrowserRouter>
        </AuthContextProvider>
      </DataProvider>
  )
}

export default App
