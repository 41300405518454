import { useState, useEffect } from "react"
import { getByRider as getTransactions } from "../../services/transactions"
import { getByRider as getRequests } from "../../services/requests"
import { getPercentage, getTime } from "../../services/options"
import { Auth } from "../../context/AuthContext"
import { IoLogoWhatsapp } from "react-icons/io"

import Transactions from "../../components/Admin/TransactionsUser"
import Requests from "../../components/Admin/RequestsUser"

function Page() {
  const { RDRSR } = Auth()
  const [ valueCashback, setValueCashback ] = useState(0)
  const [ percentage, setPercentage ] = useState()
  const [ time, setTime ] = useState()


  const [ dataTransactions, setDataTransactions ] = useState([])
  const [ dataRequests, setDataRequests ] = useState([])

  useEffect(() => {
    getInitials()
    window.scroll(0, 0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const options = {
    year: "2-digit",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    weekday: "long",
    hour12: true,
    timeZone: 'America/New_York'
  }

  const formatDate = (dateAux) => {
    if(dateAux) {
      const date = new Date(dateAux)
      const clDate = new Intl.DateTimeFormat('es-CO', options).format(date)
      return clDate
    }
  }
  
  useEffect(() => {
    getInitials()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RDRSR])

  const getInitials = async () => { 
    getPercentage()
    .then((result) => {
      setPercentage(result.value)
    })
    .catch((e) => {
      console.error(e)
    })

    if(RDRSR) {
      getTransactions(RDRSR?.id)
      .then((resTransactions) => {
        setDataTransactions(resTransactions.data)      
  
        getTime() // se obtiene periodo de tiempo de admin
        .then((resTime) => {
          setTime(resTime)
          const dateStart = new Date(resTime.start)
          const dateEnd = new Date(resTime.end)
  
          getRequests(RDRSR?.id)
          .then((resRequests) => {
            setDataRequests(resRequests)  
            let value = 0
            resRequests.forEach(item => {
              const dateRequest = new Date(item.requestTimestamp)
              if(dateRequest >= dateStart && dateRequest <= dateEnd) { //valicación de periodos de tiempo
                if(resTransactions.data[0]?.created) { //validación de pagos hechos
                  const dateCashback = new Date(resTransactions.data[0]?.created)
                  if(dateRequest >= dateCashback) value = value + Number(item.paidAmount)
                } else value = value + Number(item.paidAmount)
              }
            })
            setValueCashback(value)
          })
          .catch((e) => {
            console.error(e)
          })
        })
        .catch((e) => {
          console.error(e)
        })
      })
      .catch((e) => {
        console.error(e)
      })
    } 

  }  

  return (
    <>
      <section className="pt-5 my-5 container d-grid gap-3">
        <div className="row mt-lg-5">
          <h2><small className="bg-black text-white rounded-pill h4 px-2">{RDRSR?.id}</small> {RDRSR?.firstName} {RDRSR?.lastName}</h2>
          <small>{RDRSR?.email} - {RDRSR?.mobileNumber} </small>
          <hr></hr>
          <small className="small">Porcentaje devolución</small>
          <h4 title="Pagos recibidos en carreras desde la última devolución">{percentage}%</h4>
          <small className="small">Periodo de tiempo</small>
          <small className="small"><b>Desde {formatDate(time?.start)} <br/> Hasta {formatDate(time?.end)} </b></small>
          <br/>
          <br/>
          <small className="small">Aplica sobre</small>
          <h4 title="Pagos recibidos en carreras desde la última devolución">{new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback)}</h4>
          <small className="small">
            Valor devolución
            <a className="small text-secondary ms-2" href="https://sites.google.com/view/obii-tec/p%C3%A1gina-principal" target='_blank' rel="noreferrer"> *Aplican términos y condiciones</a>
          </small>
          <h1 title="Pagos recibidos en carreras desde la última devolución">{new Intl.NumberFormat('co-CO', { style: 'currency', currency: 'COP' }).format(valueCashback*percentage/100)}</h1>
        </div>
        <div className="row d-grid gap-2">
          <Transactions dataComponent={dataTransactions} />
        </div>
        <div className="row">
          <Requests dataComponent={dataRequests} />
        </div>
      </section>
      <a className="wtp-aux" href='https://wa.me/573144786898' target='_blank' rel="noreferrer">
        Solicitar Devolución
        <IoLogoWhatsapp className="wtp-icon"/>
      </a>
    </>
  )
}

export default Page
