import { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import Filters from "./Transactions/FiltersUser"
import Table from "./Transactions/TableUser"


export default function Component({dataComponent, getInitials}) {

  const [ data, setData ] = useState([])
  const [ count, setCount ] = useState(0)
  const [ dataPage, setDataPage ] = useState([])
  const [ page, setPage ] = useState(1)
  const numberDocs = 5

  const filterData = async (array, arrayLenght, newPage) => { 

    const newData = []
    let start = ((newPage-1)*numberDocs)
    let end = arrayLenght
    if(((newPage)*numberDocs) < end) end = ((newPage)*numberDocs)
    for (let index = start; index < end; index++) {   
      if(array[index] !== undefined) newData.push(array[index])
    }
    setPage(newPage)  
    return newData
  }


  useEffect(() => {
    setCount(dataComponent.length)
    setData(dataComponent)
    filterData(dataComponent, dataComponent.length, 1 )
    .then((res) => setDataPage(res))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComponent]) 

  const next = async () => {    
    filterData(data, count, page + 1)
    .then((res) => setDataPage(res))
  }

  const previous = async () => {    
    filterData(data, count, page -1)
    .then((res) => setDataPage(res))
  }  


  return (
    <>
      <section className="container d-grid gap-3">
        <div className="row">
          <h2 className="text-center">Transacciones</h2>
        </div>
        <div className="row d-grid gap-2">
          <Filters next={next} previous={previous} page={page} numberDocs={numberDocs} count={count} />
        </div>
        <div className="row">
          <Table dataPage={dataPage} getInitials={getInitials}/>
        </div>
      </section>
    </>
  )
}

Component.propTypes = {
  data: PropTypes.array,
  getInitials: PropTypes.func
}
