import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth } from "../context/AuthContext"
import Header from '../components/Driver/Header'
import Footer from '../components/Footer'

function Layout() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { DRVRSR } = Auth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!DRVRSR) {
      navigate('/driverAuth')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  return (
    <>
      <div className="bg">
        <Header/>
        <Outlet />
        <Footer/>
      </div>
    </>
  )
}

export default Layout
